<template>
  <div ref="wrapper" class="ys-movable"
       :style="started ? `width: ${originalParentWidth}px; height: ${originalParentHeight}px;` : ''"
       @mousedown="handleMouseDown">
    <el-popover></el-popover>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "YsMovable",
  props: {
    disabled: Boolean,
    moveVertical: {
      type: Boolean,
      default: true,
    },
    moveHorizontal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      requireRefresh: false,
      movableItems: [],
      garbages: [],
      touchedGarbage: null,
      movingItem: null,
      movingItemWidth: 0,
      movingItemHeight: 0,
      touchedItem: null,
      startMouseX: 0,
      startMouseY: 0,
      startOffsetX: 0,
      startOffsetY: 0,
      started: false,
      originalParentWidth: 0,
      originalParentHeight: 0,
      originalParentTop: 0,
      originalParentLeft: 0,
      parentRect: null,
    }
  },
  methods: {
    isYsMovable() {
      return true;
    },
    handleMouseMove(evt) {
      if (this.disabled) return;
      const movingItem = this.movingItem;
      if (!movingItem) return;

      let offsetX = evt.x - this.startMouseX;
      let offsetY = evt.y - this.startMouseY;

      let parentRect = this.$el.getBoundingClientRect();
      this.parentRect = parentRect;

      if (!this.started && Math.abs(offsetX + offsetY) > 3) {
        this.originalParentWidth = parentRect.width;
        this.originalParentHeight = parentRect.height;
        this.originalParentTop = parentRect.top;
        this.originalParentLeft = parentRect.left;
        this.started = true;
        movingItem.moveRelative(offsetX + this.startOffsetX, offsetY + this.startOffsetY);
        movingItem.startMoving();
        for (let garbage of this.garbages) {
          garbage.startMoving();
        }
        return;
      }
      if (!this.started) return;

      movingItem.moveRelative(offsetX + this.startOffsetX - parentRect.left + this.originalParentLeft, offsetY + this.startOffsetY - parentRect.top + this.originalParentTop);

      if (evt.x < parentRect.left || evt.x > parentRect.right || evt.y < parentRect.top || evt.y > parentRect.bottom) {
        return;
      }

      let isTouchSelf = movingItem.isTouchSelf(evt.x, evt.y);

      let touchedItem = null;
      let touchedGarbage = null;
      if (isTouchSelf) {
        touchedItem = this.movingItem;
        if (this.touchedGarbage != null) this.touchedGarbage.stopTouching();
        this.touchedGarbage = null;
      } else {
        touchedGarbage = this.doGetTouchedGarbage(evt.x, evt.y);
        if (this.touchedGarbage != null) this.touchedGarbage.stopTouching();
        this.touchedGarbage = touchedGarbage;
        if (touchedGarbage) {
          touchedGarbage.startTouching();
        } else {
          touchedItem = this.doGetTouchedItem(evt.x, evt.y);
          if (touchedItem && touchedItem.disableTouching) {
            touchedItem = null;
          }
        }
      }

      let onLeft = false;
      let onRight = false;
      let onTop = false;
      let onBottom = false;


      if (touchedItem && touchedItem != movingItem) {
        let rect = touchedItem.getContentRect();
        let leftBounding = rect.left + 0.5 * rect.width;
        let rightBounding = rect.right - 0.5 * rect.width;
        let topBounding = rect.top + 0.5 * rect.height;
        let bottomBounding = rect.bottom - 0.5 * rect.height;

        if (this.moveVertical && this.moveHorizontal) {
          let corner1 = (evt.x - rect.left) / (rect.right - rect.left) * (rect.bottom - rect.top) + rect.top;
          let corner2 = (evt.x - rect.left) / (rect.right - rect.left) * (rect.top - rect.bottom) + rect.bottom;
          if (corner1 > corner2) {
            let tmp = corner1;
            corner1 = corner2;
            corner2 = tmp;
          }
          if (evt.y >= corner1 && evt.y <= corner2) {
            if (evt.x <= leftBounding) {
              onLeft = true;
            } else if (evt.x > rightBounding) {
              onRight = true;
            }
          } else if (evt.y <= topBounding) {
            onTop = true;
          } else if (evt.y > bottomBounding) {
            onBottom = true;
          }
        } else if (this.moveVertical) {
          if (evt.y <= topBounding) {
            onTop = true;
          } else if (evt.y > bottomBounding) {
            onBottom = true;
          }
        } else if (this.moveHorizontal) {
          if (evt.x <= leftBounding) {
            onLeft = true;
          } else if (evt.x > rightBounding) {
            onRight = true;
          }
        }

        if (touchedItem.disableTouchingTop && onTop) {
          touchedItem = null;
        } else if (touchedItem.disableTouchingBottom && onBottom) {
          touchedItem = null;
        } else if (touchedItem.disableTouchingLeft && onLeft) {
          touchedItem = null;
        } else if (touchedItem.disableTouchingRight && onRight) {
          touchedItem = null;
        }
      }

      let isTargetRegionOverlay = false;
      if (touchedItem != this.touchedItem && touchedItem && this.touchedItem && touchedItem != movingItem) {
        let newTouchedItemRect = touchedItem.getTouchableRect();
        let oldTouchedItemRect = this.touchedItem.getTouchableRect();
        if (onTop && Math.abs(newTouchedItemRect.top - oldTouchedItemRect.bottom) < 0.001) {
          isTargetRegionOverlay = true;
        } else if (onBottom && Math.abs(newTouchedItemRect.bottom - oldTouchedItemRect.top) < 0.001) {
          isTargetRegionOverlay = true;
        } else if (onLeft && Math.abs(newTouchedItemRect.left - oldTouchedItemRect.right) < 0.001) {
          isTargetRegionOverlay = true;
        } else if (onRight && Math.abs(newTouchedItemRect.right - oldTouchedItemRect.left) < 0.001) {
          isTargetRegionOverlay = true;
        }
      }

      if (isTargetRegionOverlay) {
        touchedItem = this.touchedItem;
      } else {
        if (touchedItem == movingItem) {
          movingItem.setTouchSelf(true);
        } else if (touchedItem) {
          movingItem.setTouchSelf(false);
          if (onTop) {
            touchedItem.doShowTopTarget();
          } else if (onBottom) {
            touchedItem.doShowBottomTarget();
          } else if (onLeft) {
            touchedItem.doShowLeftTarget();
          } else if (onRight) {
            touchedItem.doShowRightTarget();
          }
        }
      }

      if (touchedItem && touchedItem != this.touchedItem) {
        this.movingItemWidth = this.movingItem.startWidth;
        this.movingItemHeight = this.movingItem.startHeight;
        touchedItem.touchItem(this.movingItemWidth, this.movingItemHeight);
        for (let movableItem of this.movableItems) {
          if (movableItem != touchedItem) {
            movableItem.doHideTargets();
          }
        }
      }

      if (touchedItem) {
        this.touchedItem = touchedItem;
      }

    },
    handleMouseDown(evt) {
      if (evt.button != 0) return;
      if (this.disabled) return;
      this.started = false;
      let movingItem = this.doGetTouchedItem(evt.x, evt.y);
      if (movingItem && movingItem.disableMoving) return;

      if (movingItem) {
        let isInterceptedByChildren = false;
        let childrenMovables = this.doGetChildMovable(movingItem);
        if (childrenMovables.length > 0) {
          for (let childrenMovable of childrenMovables) {
            if (childrenMovable.disableMoving) continue;
            if (childrenMovable.$el.contains(evt.target)) {
              isInterceptedByChildren = true;
              break;
            }
          }
        }
        if (isInterceptedByChildren) return;
      }

      this.movingItem = movingItem;
      if (movingItem) {
        let parentRect = this.$el.getBoundingClientRect();
        let rect = movingItem.$el.getBoundingClientRect();
        this.movingItemWidth = rect.width;
        this.movingItemHeight = rect.height;
        this.startMouseX = evt.x;
        this.startMouseY = evt.y;
        this.startOffsetX = rect.left - parentRect.left;
        this.startOffsetY = rect.top - parentRect.top;
      }
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
    },
    handleMouseUp(evt) {
      this.started = false;
      const movingItem = this.movingItem;
      const touchedItem = this.touchedItem;
      if (movingItem && touchedItem) {
        let direct = 'center';
        if (touchedItem.showTopTarget) {
          direct = 'top'
        } else if (touchedItem.showBottomTarget) {
          direct = 'bottom';
        } else if (touchedItem.showLeftTarget) {
          direct = 'left';
        } else if (touchedItem.showRightTarget) {
          direct = 'right';
        }
        this.$emit('move', {from: movingItem.tag, to: touchedItem.tag, direct});
      }

      if (touchedItem) {
        touchedItem.stopTouching();
        this.touchedItem = null;
      }
      if (movingItem) {
        movingItem.stopMoving();
        for (let garbage of this.garbages) {
          garbage.stopMoving();
        }

        this.movingItem = null;
        for (let movableItem of this.movableItems) {
          movableItem.doHideTargets();
        }
      }
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('mouseup', this.handleMouseUp);
    },
    doRefreshMovableItems(view) {
      let movableItems = new Array();
      for (let children of view.$children) {
        if (children.isYsMovable && children.isYsMovable()) continue;
        movableItems = movableItems.concat(this.doRefreshMovableItems(children));
        if (children.isMovableItem && children.isMovableItem(this)) {
          movableItems.push(children);
        }
      }
      return movableItems;
    },
    doRefreshGarbages(view) {
      let garbage = new Array();
      for (let children of view.$children) {
        if (children.isYsMovable && children.isYsMovable()) continue;
        garbage = garbage.concat(this.doRefreshGarbages(children));
        if (children.isYsMovableGarbage && children.isYsMovableGarbage(this)) {
          garbage.push(children);
        }
      }
      return garbage;
    },
    doGetTouchedItem(x, y) {
      let touchedItem = null;
      for (let movableItem of this.movableItems) {
        if (movableItem === this.movingItem) continue;
        if (movableItem.parentItem && movableItem.parentItem === this.movingItem) continue;
        let clientRect = movableItem.$el.getBoundingClientRect();
        if (clientRect.left <= x &&
            clientRect.right >= x &&
            clientRect.top <= y &&
            clientRect.bottom >= y) {
          touchedItem = movableItem;
          break;
        }
      }
      return touchedItem && !touchedItem.disabled ? touchedItem : null;
    },
    doGetTouchedGarbage(x, y) {
      let touchedGarbage = null;
      for (let garbage of this.garbages) {
        let clientRect = garbage.$el.getBoundingClientRect();
        if (clientRect.left <= x &&
            clientRect.right >= x &&
            clientRect.top <= y &&
            clientRect.bottom >= y) {
          touchedGarbage = garbage;
          break;
        }
      }
      return touchedGarbage && !touchedGarbage.disabled ? touchedGarbage : null;
    },
    doGetChildMovable(movableItem) {
      let childMovables = new Array();
      for (let children of movableItem.$children) {
        if (children.isYsMovable && children.isYsMovable()) {
          for (let childrenMovableItem of children.$children) {
            if (childrenMovableItem.isMovableItem) {
              childMovables.push(childrenMovableItem);
            }
          }
        }
      }
      return childMovables;
    },
    refreshMovableItems() {
      this.requireRefresh = true;
      this.$nextTick(() => {
        if (this.requireRefresh) {
          this.requireRefresh = false;
          this.movableItems = this.doRefreshMovableItems(this);
          this.garbages = this.doRefreshGarbages(this);
        }
      })
    }
  },
  mounted() {
    //document.addEventListener('mousemove', this.handleMouseMove);
    //document.addEventListener('mousedown', this.handleMouseDown);
    //document.addEventListener('mouseup', this.handleMouseUp);
  },
  destroyed() {
    //document.removeEventListener('mousemove', this.handleMouseMove);
    //document.removeEventListener('mousedown', this.handleMouseDown);
    //document.removeEventListener('mouseup', this.handleMouseUp);
  }
}
</script>

<style scoped>

.ys-movable {
  position: relative;
  box-sizing: border-box;
  user-select: none;
}

</style>