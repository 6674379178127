<template>
  <div class="ys-movable-item-container">
    <transition :name="starting || stopping ? 'slide-fade-none' : 'slide-fade-vertical'">
      <div ref="placeholder" class="placeholder" :style="`width: ${startWidth}px; height: ${startHeight}px;`"
           v-show="moving && touchSelf"></div>
    </transition>
    <div ref="wrapper"
         :class="[
        'ys-movable-item',
        {
          'is-moving': moving,
        }
    ]">
      <div class="ys-movable-vertical-wrapper layout-vertical" :class="innerClass">
        <transition :name="stopping ? 'slide-fade-none' : 'slide-fade-vertical'">
          <div ref="top" class="ys-target-region" v-show="showTarget && showTopTarget"
               :style="`width: ${movingItemWidth}px; height: ${movingItemHeight}px;`">
          </div>
        </transition>
        <div class="ys-movable-horizontal-wrapper layout-horizontal layout-top" :class="innerClass">
          <transition :name="stopping ? 'slide-fade-none' : 'slide-fade-horizontal'">
            <div ref="left" class="ys-target-region" v-show="showTarget && showLeftTarget"
                 :style="`width: ${movingItemWidth}px; height: ${movingItemHeight}px;`"></div>
          </transition>
          <div class="current-target" ref="content"
               :style="`left: ${offsetLeft}px; top: ${offsetTop}px; ${moving ? `width: ${startWidth}px; height: ${startHeight}px;` : ''}`">
            <slot class="content"></slot>
          </div>
          <transition :name="stopping ? 'slide-fade-none' : 'slide-fade-horizontal'">
            <div ref="right" class="ys-target-region" v-show="showTarget && showRightTarget"
                 :style="`width: ${movingItemWidth}px; height: ${movingItemHeight}px;`"></div>
          </transition>
        </div>
        <transition :name="stopping ? 'slide-fade-none' : 'slide-fade-vertical'">
          <div ref="bottom" class="ys-target-region" v-show="showTarget && showBottomTarget"
               :style="`width: ${movingItemWidth}px; height: ${movingItemHeight}px;`"></div>
        </transition>
      </div>
      <el-popover></el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "YsMovableItem",
  props: {
    disableMoving: Boolean,
    disableTouching: Boolean,
    disableTouchingTop: Boolean,
    disableTouchingBottom: Boolean,
    disableTouchingLeft: Boolean,
    disableTouchingRight: Boolean,
    tag: [String, Number],
    isMoving: Boolean,
    innerClass: String
  },
  data() {
    return {
      parent: null,
      parentItem: null,
      showTarget: false,
      showTopTarget: false,
      showBottomTarget: false,
      showLeftTarget: false,
      showRightTarget: false,
      starting: false,
      stopping: false,
      moving: false,
      offsetLeft: 0,
      offsetTop: 0,
      startWidth: 0,
      startHeight: 0,
      touchSelf: false,
      movingItemWidth: 0,
      movingItemHeight: 0,
    }
  },
  methods: {
    doShowTopTarget() {
      this.showTarget = true;
      this.showTopTarget = true;
      this.showBottomTarget = false;
      this.showLeftTarget = false;
      this.showRightTarget = false;
    },
    doShowBottomTarget() {
      this.showTarget = true;
      this.showTopTarget = false;
      this.showBottomTarget = true;
      this.showLeftTarget = false;
      this.showRightTarget = false;
    },
    doShowLeftTarget() {
      this.showTarget = true;
      this.showTopTarget = false;
      this.showBottomTarget = false;
      this.showLeftTarget = true;
      this.showRightTarget = false;
    },
    doShowRightTarget() {
      this.showTarget = true;
      this.showTopTarget = false;
      this.showBottomTarget = false;
      this.showLeftTarget = false;
      this.showRightTarget = true;
    },
    doHideTargets() {
      if (!this.showTarget && !this.moving) {
        this.stopping = true;
      }
      this.showTarget = false;
      this.showTopTarget = false;
      this.showBottomTarget = false;
      this.showLeftTarget = false;
      this.showRightTarget = false;
    },
    getTouchableRect() {
      if (this.moving) {
        return this.$refs.placeholder.getBoundingClientRect();
      } else {
        return this.$el.getBoundingClientRect();
      }
    },
    getContentRect() {
      return this.$refs.content.getBoundingClientRect();
    },
    isMovableItem(parent) {
      return parent == this.parent;
    },
    isTouchSelf(x, y) {
      let rect = this.$refs.placeholder.getBoundingClientRect();
      let touchSelf = x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom;
      return touchSelf;
    },
    setTouchSelf(touchSelf) {
      this.touchSelf = touchSelf;
    },
    touchItem(movingItemWidth, movingItemHeight) {
      this.stopping = false;
      let rect = this.getContentRect();
      this.movingItemWidth = rect.width;
      this.movingItemHeight = movingItemHeight;
      //this.movingItemWidth = movingItemWidth;
      //this.movingItemHeight = movingItemHeight;
    },
    startMoving() {
      this.$emit('update:moving', true);
      this.$nextTick(()=> {
        let rect = this.getContentRect();
        this.startWidth = rect.width;
        this.startHeight = rect.height;
        this.touchSelf = true;
        this.starting = true;
        this.stopping = false;
        this.moving = true;
      });
    },
    moveRelative(x, y) {
      this.offsetLeft = x;
      this.offsetTop = y;
      this.starting = false;
    },
    stopMoving() {
      this.$emit('update:moving', false);
      this.moving = false;
    },
    stopTouching() {
      this.stopping = true;
    }
  },
  mounted() {
    let parent = this.$parent;
    while (parent && !parent.isYsMovable) {
      parent = parent.$parent;
    }
    if (parent) {
      this.parent = parent;
      this.parent.refreshMovableItems();
    }
  },
  destroyed() {
    if (this.parent) {
      this.parent.refreshMovableItems();
    }
  }
}
</script>

<style scoped>

ys-movable-item-container {
  box-sizing: border-box;
}

.placeholder {
  background-color: #f0c78a;
  border: 2px solid #ff5500;
  border-radius: 16px;
  box-sizing: border-box;
}

.ys-movable-item {
  user-select: none;
}


.ys-movable-item.is-moving > .ys-movable-vertical-wrapper > .ys-movable-horizontal-wrapper > .current-target {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 8px #bec0c0;
  background-color: white;
  cursor: grabbing !important;
  z-index: 99999;
  position: absolute;
  box-sizing: border-box;
}

.ys-target-region {
  background-color: #f0c78a;
  border: 2px solid #ff5500;
  border-radius: 16px;
  box-sizing: border-box;
}

.ys-movable-vertical-wrapper, .ys-movable-horizontal-wrapper, .current-target, .content {
  box-sizing: border-box;
}

.slide-fade-vertical-enter-active {
  transition: all .5s ease;
}

.slide-fade-vertical-enter {
  height: 0 !important;
  max-height: 0;
  opacity: 0;
}

.slide-fade-vertical-leave-active {
  transition: all .5s ease;
}

.slide-fade-vertical-leave {
  opacity: 1;
  border: none;
  height: auto;
  background-color: #f1f1f1;
}

.slide-fade-vertical-leave-to {
  opacity: 0;
  border: none;
  height: 0 !important;
  background-color: #f1f1f1;
}

.slide-fade-horizontal-enter-active {
  transition: all .5s ease;
}

.slide-fade-horizontal-enter {
  width: 0 !important;
  max-height: 0;
  opacity: 0;
}

.slide-fade-horizontal-leave-active {
  transition: all .5s ease;
}

.slide-fade-horizontal-leave {
  opacity: 1;
  border: none;
  width: auto;
  background-color: #f1f1f1;
}

.slide-fade-horizontal-leave-to {
  opacity: 0;
  border: none;
  width: 0 !important;
  background-color: #f1f1f1;
}


</style>